import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import * as Yup from 'yup'
import {
  DeleteOutline,
  EditOutlined,
  SaveOutlined,
  CancelOutlined,
} from '@mui/icons-material'
import Button from '@/v2/components/common/Button'
import IconButton from '@/v2/components/common/IconButton'
import Typography from '@/v2/components/common/Typography'
import TextField from '@/v2/components/common/TextField'
import { generateEmptyProcedure } from '../../utils'
import Box from '@mui/material/Box'
import {
  useAddClaimItem,
  useUpdateClaimItem,
  useRemoveClaimItem,
} from '@/v2/hooks/insurance/claim-subforms/useClaimItems'
import SelectCalendar from '@/v2/components/common/SelectCalendar'

const procedureSchema = Yup.object().shape({
  procedureCode: Yup.string().required('Code is required'),
  fee: Yup.number()
    .transform(value => (value === '' ? null : value))
    .required('Fee is required')
    .positive('Fee must be positive'),
  quantity: Yup.number()
    .transform(value => (value === '' ? null : value))
    .required('Quantity is required')
    .positive('Quantity must be positive'),
  startDate: Yup.string().required('Start date is required'),
})

const Wrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 30px;
`

const Procedures = ({ values = {}, onChange, claimId }) => {
  const theme = useTheme()
  const [procedures, setProcedures] = useState(values?.procedure || [])
  const [editingProcedureId, setEditingProcedureId] = useState(null)
  const [isAdding, setIsAdding] = useState(false)
  const [initialProcedureData, setInitialProcedureData] = useState(
    generateEmptyProcedure()
  )
  const [validationErrors, setValidationErrors] = useState({})

  const { addClaimItem } = useAddClaimItem(claimId)
  const { updateClaimItem } = useUpdateClaimItem(claimId, editingProcedureId)
  const { removeClaimItem } = useRemoveClaimItem(claimId)

  useEffect(() => {
    setProcedures(values?.items || [])
  }, [values.items])

  const updateProcedure = (procedureId, updatedValue) => {
    const updatedProcedures = procedures.map(item =>
      item.id === procedureId ? { ...item, ...updatedValue } : item
    )
    setProcedures(updatedProcedures)
    if (onChange) onChange({ ...values, procedure: updatedProcedures })
  }

  const handleDeleteProcedure = async procedureId => {
    const res = await removeClaimItem(procedureId)
    if (!res.error) {
      const updatedProcedures = procedures.filter(
        ({ id }) => id !== procedureId
      )
      setProcedures(updatedProcedures)
      if (onChange) onChange({ ...values, procedure: updatedProcedures })
    }
  }

  const handleAddProcedure = () => {
    setIsAdding(true)
    setEditingProcedureId(null)
    setInitialProcedureData(generateEmptyProcedure())
    setValidationErrors({})
  }

  const handleSaveProcedure = async () => {
    try {
      await procedureSchema.validate(initialProcedureData, {
        abortEarly: false,
      })
      setValidationErrors({})

      const procedureWithId = initialProcedureData.id
        ? initialProcedureData
        : { ...initialProcedureData, id: Date.now() }

      if (isAdding) {
        const body = {
          item: {
            quantity: procedureWithId.quantity,
            fee: procedureWithId.fee,
            procedureCode: procedureWithId.procedureCode,
            startDate: procedureWithId.startDate,
            endDate: procedureWithId.endDate,
          },
        }

        const res = await addClaimItem(body)
        if (!res.error && res.id) {
          const newProcedureWithRealId = { ...procedureWithId, id: res.id }
          const newProcedures = [...procedures, newProcedureWithRealId]
          setProcedures(newProcedures)
          if (onChange) onChange({ ...values, procedure: newProcedures })
        }
      } else if (editingProcedureId) {
        const body = {
          item: {
            quantity: procedureWithId.quantity,
            fee: procedureWithId.fee,
            procedureCode: procedureWithId.procedureCode,
            startDate: procedureWithId.startDate,
            endDate: procedureWithId.endDate,
          },
        }

        const res = await updateClaimItem(body)
        if (!res.error) {
          const updatedProcedures = procedures.map(procedure =>
            procedure.id === editingProcedureId ? procedureWithId : procedure
          )
          setProcedures(updatedProcedures)
          if (onChange) onChange({ ...values, procedure: updatedProcedures })
        }
      }

      setIsAdding(false)
      setEditingProcedureId(null)
      setInitialProcedureData(generateEmptyProcedure())
    } catch (validationErrors) {
      const errors = {}
      validationErrors.inner.forEach(err => {
        errors[
          `${isAdding ? 'newProcedure' : editingProcedureId}.${err.path}`
        ] = err.message
      })
      setValidationErrors(errors)
    }
  }

  const handleEditProcedure = procedureId => {
    setEditingProcedureId(procedureId)
    setIsAdding(false)
    const procedureToEdit = procedures.find(
      procedure => procedure.id === procedureId
    )
    setInitialProcedureData(procedureToEdit)
    setValidationErrors({})
  }

  const handleCancelEdit = () => {
    setEditingProcedureId(null)
    setIsAdding(false)
    setInitialProcedureData(generateEmptyProcedure())
    setValidationErrors({})
  }

  return (
    <>
      <Grid item xs={12} sx={{ marginTop: '12px' }}>
        <Typography variant="h5" bold>
          Items
        </Typography>
      </Grid>
      {procedures.map((procedure, index) => (
        <Grid item key={procedure.id} xs={12}>
          <Wrapper>
            <Procedure
              isEdit={editingProcedureId === procedure.id}
              showLabel={index === 0}
              values={
                editingProcedureId === procedure.id
                  ? initialProcedureData
                  : procedure
              }
              errorAffixe={
                editingProcedureId === procedure.id
                  ? editingProcedureId
                  : `procedure[${index}]`
              }
              error={validationErrors}
              onChange={value => setInitialProcedureData(value)}
            />
            <Box display="flex" flexDirection="row-reverse" gap="8px">
              {editingProcedureId === procedure.id ? (
                <>
                  <IconButton
                    title="Save Item"
                    aria-label="Save Item"
                    bordercolor="silver"
                    sx={{
                      background: theme.palette.silver.main,
                      marginBottom: '4px',
                    }}
                    onClick={handleSaveProcedure}
                  >
                    <SaveOutlined color="action" />
                  </IconButton>
                  <IconButton
                    title="Cancel Changes"
                    aria-label="Cancel Changes"
                    bordercolor="silver"
                    sx={{
                      background: theme.palette.silver.main,
                      marginBottom: '4px',
                    }}
                    onClick={handleCancelEdit}
                  >
                    <CancelOutlined color="error" />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    title="Edit Item"
                    aria-label="Edit Item"
                    bordercolor="silver"
                    sx={{
                      background: theme.palette.silver.main,
                      marginBottom: '4px',
                    }}
                    onClick={() => handleEditProcedure(procedure.id)}
                  >
                    <EditOutlined color="action" />
                  </IconButton>
                  <IconButton
                    title="Delete Item"
                    aria-label="Delete Item"
                    bordercolor="silver"
                    sx={{
                      background: theme.palette.silver.main,
                      marginBottom: '4px',
                    }}
                    onClick={() => handleDeleteProcedure(procedure.id)}
                  >
                    <DeleteOutline color="action" />
                  </IconButton>
                </>
              )}
            </Box>
          </Wrapper>
        </Grid>
      ))}

      <Grid item xs={12} sx={{ marginTop: '12px' }}>
        {!isAdding && editingProcedureId === null ? (
          <Button fullWidth variant="outlined" onClick={handleAddProcedure}>
            <Typography bold variant="label">
              + Add an item
            </Typography>
          </Button>
        ) : null}
      </Grid>
      {isAdding && (
        <Grid item xs={12}>
          <Wrapper>
            <Procedure
              values={initialProcedureData}
              errorAffixe="newProcedure"
              error={validationErrors}
              isEdit={true}
              isAdding={true}
              onChange={newProcedure => setInitialProcedureData(newProcedure)}
            />
            <Box display="flex" flexDirection="row-reverse" gap="8px">
              <IconButton
                title="Save Item"
                aria-label="Save Item"
                bordercolor="silver"
                sx={{
                  background: theme.palette.silver.main,
                  marginBottom: '4px',
                }}
                onClick={handleSaveProcedure}
              >
                <SaveOutlined color="action" />
              </IconButton>
              <IconButton
                title="Cancel Changes"
                aria-label="Cancel Changes"
                bordercolor="silver"
                sx={{
                  background: theme.palette.silver.main,
                  marginBottom: '4px',
                }}
                onClick={handleCancelEdit}
              >
                <CancelOutlined color="action" />
              </IconButton>
            </Box>
          </Wrapper>
        </Grid>
      )}
    </>
  )
}

const Procedure = ({
  isEdit = false,
  showLabel = true,
  values = {},
  error = {},
  errorAffixe,
  onChange,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={2}>
      <SelectCalendar
        label="Start date"
        labelFormat="MM/dd/yyyy"
        isBasicCalendar
        value={values.startDate}
        error={error[`${errorAffixe}.startDate`]}
        onChange={value => onChange({ ...values, startDate: value })}
        disabled={!isEdit}
      />
    </Grid>
    <Grid item xs={2}>
      <SelectCalendar
        label="End date"
        labelFormat="MM/dd/yyyy"
        isBasicCalendar
        value={values.endDate}
        error={error[`${errorAffixe}.endDate`]}
        onChange={value => onChange({ ...values, endDate: value })}
        disabled={!isEdit}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        fullWidth
        name="procedureCode"
        label={showLabel ? 'Code' : ''}
        placeholder="Procedure Code"
        value={values.procedureCode || ''}
        error={error[`${errorAffixe}.procedureCode`]}
        disabled={!isEdit}
        onChange={value => onChange({ ...values, procedureCode: value })}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        fullWidth
        name="fee"
        label={showLabel ? 'Fee' : ''}
        placeholder="Procedure Fee"
        prefix="$"
        value={values.fee || ''}
        error={error[`${errorAffixe}.fee`]}
        disabled={!isEdit}
        onChange={value =>
          onChange({ ...values, fee: value === '' ? null : Number(value) })
        }
      />
    </Grid>
    <Grid item xs={2}>
      <TextField
        fullWidth
        name="quantity"
        label={showLabel ? 'Quantity' : ''}
        placeholder="Quantity"
        value={values.quantity || ''}
        error={error[`${errorAffixe}.quantity`]}
        disabled={!isEdit}
        onChange={value =>
          onChange({ ...values, quantity: value === '' ? null : Number(value) })
        }
      />
    </Grid>
  </Grid>
)

export default Procedures
