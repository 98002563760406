import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import SelectField from '@/v2/components/common/SelectField'

const formatProviderOptions = providers =>
  providers?.map(provider => ({
    value: provider.npi,
    label: provider.name,
  }))

const formatInsuranceOptions = insurances =>
  insurances.map(insurance => ({
    value: insurance.id,
    label: insurance.payer.name,
  }))

const Form = ({
  values = {
    providerNPI: '',
    insuranceId: '',
  },
  error = {},
  onChange,
  providers,
  insurances,
}) => {
  useEffect(() => {
    if (insurances.length === 1) {
      const [insurance] = insurances
      onChange({
        insuranceId: insurance.id,
      })
    }
  }, insurances)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectField
          fullWidth
          noMargin
          name="insuranceId"
          label="Insurance"
          placeholder="Choose an option"
          options={formatInsuranceOptions(insurances)}
          value={values.insuranceId}
          error={error.insuranceId}
          onChange={value => onChange({ insuranceId: value })}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          fullWidth
          noMargin
          name="providerNPI"
          label="Provider"
          placeholder="Choose an option"
          options={formatProviderOptions(providers)}
          value={values.providerNPI}
          error={error.providerNPI}
          onChange={value => onChange({ providerNPI: value })}
        />
      </Grid>
    </Grid>
  )
}

export default Form
