import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Typography from '@/v2/components/common/Typography'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { useClaims } from '@/v2/hooks/insurance/useClaims'
import Button from '@/v2/components/common/Button'
import useModals from '@/v2/hooks/useModals'
import Box from '@mui/material/Box'
import ClaimInfo from './ClaimInfo'
import RTClaims from '@/v2/hooks/subscriptions/messages/RTClaims'

const ClaimsTab = () => {
  const { id } = useParams()
  const { goTo } = useModals()
  const { patient } = usePatientContext()
  const { claims, isLoading, isError } = useClaims(id)
  const { onOpenSnackbar } = useSnackbarContext()

  useEffect(() => {
    if (!isLoading && !!isError)
      onOpenSnackbar("It wasn't possible to load the patient claims.", 'error')
  }, [isLoading, isError, onOpenSnackbar])

  if (isLoading)
    return (
      <Typography
        variant="h5"
        fontWeight="300"
        sx={{ textAlign: 'center', marginTop: '40px' }}
      >
        Loading...
      </Typography>
    )

  return (
    <>
      <RTClaims />
      <Box display={'flex'} flexDirection={'row-reverse'}>
        <Button
          variant="text"
          onClick={() =>
            goTo(`v2/patient/${patient.id}/claim/view`, {
              modalType: 'create',
              patientId: patient.id,
              patient,
            })
          }
        >
          <Typography variant="h5">Create Claim</Typography>
        </Button>
      </Box>
      {claims.map(item => (
        <ClaimInfo key={item.id} claim={item} patient={patient} />
      ))}
      {claims.length === 0 && (
        <Typography
          variant="h5"
          fontWeight="300"
          sx={{ textAlign: 'center', marginTop: '40px' }}
        >
          No claims found
        </Typography>
      )}
    </>
  )
}

export default ClaimsTab
