import getRandomId from '@/v2/utils/random-id'

export const generateEmptyProvider = () => ({
  id: getRandomId(),
  provider: '',
  type: '',
  specialty: '',
  taxonomy: '',
  taxId: '',
  npi: '',
  npiType: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  kind: '',
})

export const generateEmptyProcedure = () => ({
  id: getRandomId(),
  procedureCode: '',
  fee: null,
  quantity: null,
})

export const genderOptions = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
]

export const sequenceCodeOptions = [
  { label: 'Primary', value: 'primary' },
  { label: 'Secondary', value: 'secondary' },
  { label: 'Tertiary', value: 'tertiary' },
]

export const modalTitle = {
  edit: 'Edit Claim',
  view: 'View Claim',
  create: 'Create Claim',
}

export const ctaButtonTitle = {
  edit: 'Save',
  view: 'Close',
  create: 'Create',
}

export const modalHeihgt = {
  edit: 'auto',
  view: '45vh',
  create: 'auto',
}

export const imageTypeCode = [
  { label: 'B4', value: 'B4' },
  { label: 'DA', value: 'DA' },
  { label: 'DG', value: 'DG' },
  { label: 'EB', value: 'EB' },
  { label: 'OZ', value: 'OZ' },
  { label: 'P6', value: 'P6' },
  { label: 'RB', value: 'RB' },
  { label: 'RR', value: 'RR' },
  { label: 'XP', value: 'XP' },
  { label: '03', value: '03' },
]

export const imageOrientations = [
  {
    label: 'Left',
    value: 'LEFT',
  },
  {
    label: 'Right',
    value: 'RIGHT',
  },
]

export const convertFileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
