import { useQuery, useQueryClient } from '@tanstack/react-query'
import { baseURL, requestMiddleware, headers } from '@/v2/constants/requests'

export const useEligibilities = patientId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Eligibilities', `${patientId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/insurances/eligibilities.json`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the patient eligibilities")
      }
      const result = await res.json()
      return result
    },
    enabled: !!patientId,
  })
  return { eligibilities: data, isLoading, isError }
}

export const useProviders = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Providers'],
    queryFn: async () => {
      const res = await fetch(`${baseURL}/v2/rest/providers`)
      if (!res.ok) {
        throw new Error("We couldn't find any providers")
      }
      const result = await res.json()
      return result
    },
    enabled: true,
  })

  return { providers: data, isLoading, isError }
}

export const useAddCheckEligibility = patientId => {
  const queryClient = useQueryClient()
  const { mutateAsync: addCheckEligibility, ...others } = requestMiddleware({
    key: ['AddCheckEligibility', `${patientId}`],
    request: async body =>
      await fetch(
        `${baseURL}/v2/rest/insurances/${body.insuranceId}/eligibilities.json`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({
            eligibility: body.eligibility,
          }),
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Eligibilities', `${patientId}`],
      })
    },
  })

  return { addCheckEligibility, ...others }
}

export const useShowEligibility = (insuranceId, eligibilityId) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['ShowEligibility', `${eligibilityId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/insurances/${insuranceId}/eligibilities/${eligibilityId}`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the patient eligibilities")
      }
      const result = await res.json()
      return result
    },
    enabled: !!eligibilityId,
  })

  return { eligibilityInfo: data, isLoading, isError }
}

export const useSyncPatientData = (insuranceId, eligibilityId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: syncPatientData, ...others } = requestMiddleware({
    key: ['SyncPatientData', `${eligibilityId}`],
    request: async () =>
      await fetch(
        `${baseURL}/v2/rest/insurances/${insuranceId}/eligibilities/${eligibilityId}/sync_patient_data`,
        {
          method: 'PUT',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ShowEligibility', `${eligibilityId}`],
      })
    },
  })

  return { syncPatientData, ...others }
}
