import { useQuery, useQueryClient } from '@tanstack/react-query'
import { baseURL, requestMiddleware, headers } from '@/v2/constants/requests'

export const useGetclaimAttachments = claimId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['ClaimAttachments', `${claimId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/attachments.json`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the claim attachments")
      }
      const result = await res.json()
      return result
    },
    enabled: !!claimId,
  })

  return { claimAttachments: data, isLoading, isError }
}

export const useCreateClaimAttachment = claimId => {
  const queryClient = useQueryClient()
  const { mutateAsync: createClaimAttachment, ...others } = requestMiddleware({
    key: ['CreateClaimAttachment', `${claimId}`],
    request: async data =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/attachments`,
        {
          method: 'POST',
          body: data,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ClaimAttachments', `${claimId}`],
      })
    },
  })

  return { createClaimAttachment, ...others }
}

export const useUpdateClaimAttachment = claimId => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateClaimAttachment, ...others } = requestMiddleware({
    key: ['UpdateClaimAttachment'],
    request: async data =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/attachments/${data.attachmentId}`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify(data.body),
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ClaimAttachments', `${claimId}`],
      })
    },
  })

  return { updateClaimAttachment, ...others }
}

export const useRemoveClaimAttachment = claimId => {
  const queryClient = useQueryClient()
  const { mutateAsync: removeClaimAttachment, ...others } = requestMiddleware({
    key: ['RemoveClaimAttachment'],
    request: async attachmentId =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/attachments/${attachmentId}.json`,
        {
          method: 'DELETE',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ClaimAttachments', `${claimId}`],
      })
    },
  })

  return { removeClaimAttachment, ...others }
}

export const useCreateClaimAttachmentImages = (attachmentId, claimId) => {
  const queryClient = useQueryClient()
  const {
    mutateAsync: createClaimAttachmentImages,
    ...others
  } = requestMiddleware({
    key: ['CreateClaimAttachmentImages', `${attachmentId}`],
    request: async data =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/attachments/${attachmentId}/images`,
        {
          method: 'POST',
          body: data,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ClaimAttachments', `${claimId}`],
      })
    },
  })

  return { createClaimAttachmentImages, ...others }
}

export const useUpdateClaimAttachmentImages = (attachmentId, claimId) => {
  const queryClient = useQueryClient()
  const {
    mutateAsync: updateClaimAttachmentImages,
    ...others
  } = requestMiddleware({
    key: ['UpdateClaimAttachmentImages'],
    request: async body => {
      const formData = new FormData()
      formData.append('image[type_code]', body.data.typeCode)
      formData.append('image[orientation_type]', body.data.orientationType)
      formData.append('image[date]', body.data.date)
      return await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/attachments/${attachmentId}/images/${body.imageId}`,
        {
          method: 'PUT',
          body: formData,
        }
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ClaimAttachments', `${claimId}`],
      })
    },
  })
  return { updateClaimAttachmentImages, ...others }
}

export const useRemoveClaimAttachmentImages = (attachmentId, claimId) => {
  const queryClient = useQueryClient()
  const {
    mutateAsync: removeClaimAttachmentImage,
    ...others
  } = requestMiddleware({
    key: ['RemoveClaimAttachmentImage'],
    request: async imageId =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/attachments/${attachmentId}/images/${imageId}`,
        {
          method: 'DELETE',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ClaimAttachments', `${claimId}`],
      })
    },
  })

  return { removeClaimAttachmentImage, ...others }
}
