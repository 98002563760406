import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { format, startOfMonth } from 'date-fns'
import { DATE_PARAM_FORMAT } from '@/v2/constants'
import { nowOnClinicTimezone } from '@/v2/utils/convert'
import Calendar from '@/v2/containers/calendar'
import Patient from '@/v2/containers/patient'
import Messages from '@/v2/containers/messages'
import NewConversation from '@/v2/containers/messages/NewConversation'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import MobileMainRoutes from './MobileMainRoutes'
import Login from '@/v2/containers/auth-login'
import NewPassword from '@/v2/containers/auth-new-password'
import ResetPassword from '@/v2/containers/auth-reset-password'

const MainRoutes = () => {
  const { isMobile } = useDetectDevice()
  const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true'

  if (isMobile) return <MobileMainRoutes />

  const rootPath = `/v2/calendar/month/${format(
    startOfMonth(Date.now()),
    DATE_PARAM_FORMAT
  )}`

  return (
    <Switch>
      {/* Authentication Routes */}
      {!isLoggedIn && (
        <>
          <Route exact path="/v2/login" component={Login} />
          <Route exact path="/v2/new-password" component={NewPassword} />
          <Route exact path="/v2/reset-password" component={ResetPassword} />
        </>
      )}
      {/* End Authentication Routes */}

      {/* Messages Routes */}
      <Route exact path="/v2/messages/new/:phone" component={NewConversation} />
      <Route
        exact
        path="/v2/messages/start-conversation/:patientId"
        component={NewConversation}
      />
      <Route path="/v2/messages/:conversationId?" component={Messages} />
      {/* End Messages Routes */}

      {/* Calendar Routes */}
      <Route
        exact
        path="/v2/calendar"
        render={() => <Redirect to={rootPath} />}
      />
      <Route
        exact
        path="/v2/calendar/day"
        render={() => (
          <Redirect
            to={`/v2/calendar/day/${format(
              nowOnClinicTimezone(),
              DATE_PARAM_FORMAT
            )}`}
          />
        )}
      />
      <Route
        exact
        path="/v2/calendar/month"
        render={() => <Redirect to={rootPath} />}
      />
      <Route path="/v2/calendar/:view?/:date?" component={Calendar} />
      {/* End Calendar Routes */}

      {/* Patient Routes */}
      <Route
        exact
        path="/v2/patient/:id?"
        render={({ match }) => (
          <Redirect to={`/v2/patient/${match.params.id}/profile`} />
        )}
      />

      <Route path="/v2/patient/:id?/:view?" component={Patient} />
      {/* End Patient Routes */}

      {/* Root */}
      <Route exact path="/v2" render={() => <Redirect to={rootPath} />} />
      {/* End Root */}

      {/* Redirects */}
      <Route
        exact
        path="/settings"
        render={() => {
          window.location.href = '/settings'
          return null
        }}
      />
      {/* End Redirects */}

      {/* Error Routes */}
      <Route
        path="/500"
        render={() => {
          window.location.href = '/500'
          return null
        }}
      />
      <Route
        path="*"
        render={() => {
          window.location.href = '/404'
          return null
        }}
      />
      {/* End Error Routes */}
    </Switch>
  )
}

export default MainRoutes
