import React, { useState, useEffect } from 'react'
import Modal from '@/v2/components/common/Modal'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useAddClaim, useUpdateClaimInfo } from '@/v2/hooks/insurance/useClaims'
import { generateEmptyProcedure, generateEmptyProvider } from './utils'
import Form from './claim-form/Form'
import { validateClaim } from './claim-form/validator'
import Show from './claim-show/Show'
import Edit from './claim-form/Edit'
import useModals from '@/v2/hooks/useModals'
import { modalTitle, ctaButtonTitle, modalHeihgt } from './utils'
import { Prompt } from 'react-router'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const ClaimForm = ({
  isOpen,
  onClose,
  modalType,
  insurance,
  isEdit,
  patientId,
  claimId,
  insuranceId,
  claim,
  patient,
}) => {
  const { goTo } = useModals()
  const { addClaim } = useAddClaim(patientId)
  const { updateClaim } = useUpdateClaimInfo(
    claim?.insuranceId,
    claim?.id,
    patientId
  )
  const [values, setValues] = useState({
    insuranceId: '',
    subscriber: {
      subscriber: '',
      dob: '',
      firstName: '',
      lastName: '',
      gender: '',
      relationship: '',
      sequenceCode: '',
      memberId: '',
    },
    providers: [generateEmptyProvider()],
    procedure: [generateEmptyProcedure()],
  })
  const [error, setError] = useState({})
  const { onOpenSnackbar } = useSnackbarContext()
  const [showButtons, setShowButtons] = useState(true)
  const [showAlert, setShowAlert] = useState(false)

  const editTriggered = value => {
    setShowAlert(value)
    setShowButtons(value)
  }

  useEffect(() => {
    if (modalType === 'view' || modalType === 'edit') {
      setShowButtons(false)
    }

    if (modalType === 'create') {
      setValues({
        ...values,
        insuranceId: insurance?.id,
      })
    }
  }, [modalType])

  const modalContent = {
    create: (
      <Form
        isEdit={isEdit}
        values={values}
        error={error}
        onChange={value => setValues({ ...values, ...value })}
        patient={patient}
      />
    ),
    view: <Show claimId={claimId} insuranceId={insuranceId} />,
    edit: (
      <Edit
        claim={claim}
        patientId={patientId}
        insuranceId={insuranceId}
        onTriggerEdit={editTriggered}
        error={error}
        onChange={value => setValues({ ...values, ...value })}
        patient={patient}
      />
    ),
  }

  const handleSave = async body => {
    const res = await addClaim({
      insuranceId: values.insuranceId,
      body,
    })
    if (!res.error) {
      onOpenSnackbar('Created claim', 'success')
      onClose()
      goTo(`v2/patient/${patientId}/claim/edit`, {
        modalType: 'edit',
        patientId,
        insuranceId: values.insuranceId,
        isEdit: true,
        claim: res,
        patient,
      })
    }
  }

  const handleEdit = async body => {
    setShowAlert(false)
    const res = await updateClaim({
      insuranceId: values.insuranceId,
      body,
    })
    if (!res.error) {
      onOpenSnackbar('Claim successfully edited', 'success')
      onClose()
    }
  }

  const handleSubmit = async () => {
    if (modalType === 'view') {
      onClose()
      return
    }

    const validErrors = await validateClaim(values)
    setError(validErrors || {})

    if (!validErrors) {
      try {
        const { subscriber, ...subscriberInfo } = values.subscriber
        const procedure = values.procedure
          .map(({ id, ...data }) => data)
          .filter(
            ({ procedureCode, fee, quantity }) =>
              !(procedureCode === '' && fee === null && quantity === null)
          )
        const data = {
          subscriberAttributes: subscriberInfo,
          itemsAttributes: procedure,
        }
        const body = {
          claim: {
            ...data,
          },
        }
        if (modalType === 'edit') {
          await handleEdit(body)
        }

        if (modalType === 'create') {
          await handleSave(body)
        }
      } catch (error) {
        onOpenSnackbar(`${error}`, 'error')
      }
    }
  }

  return (
    <>
      <Prompt
        when={showAlert}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Modal
        isOpen={isOpen}
        title={modalTitle[modalType]}
        maxWidth="lg"
        confirmLabel={ctaButtonTitle[modalType]}
        onConfirm={() => handleSubmit()}
        showConfirm={showButtons}
        showClose={showButtons}
        onClose={onClose}
        fixedHeight={modalHeihgt[modalType]}
      >
        {modalContent[modalType]}
        {isEdit && showButtons && (
          <Grid item xs={12} mt={2}>
            <Box display={'flex'} flexDirection={'row-reverse'}>
              <Typography variant="h5" color="error">
                You have unsaved changes. Please save before closing.
              </Typography>
            </Box>
          </Grid>
        )}
      </Modal>
    </>
  )
}

export default ClaimForm
