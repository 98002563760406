import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import SelectField from '@/v2/components/common/SelectField'
import { useInsurances } from '@/v2/hooks/insurance/useInsurances'
import Subscriber from './subforms/Subscriber'
import Patient from './subforms/Patient'

const Form = ({
  isEdit = false,
  values = {},
  error = {},
  onChange,
  patient,
}) => {
  const { id } = useParams()
  const { insurances } = useInsurances(id)
  const insuranceOptions = (insurances = []) =>
    insurances.map(insurance => ({
      value: insurance.id,
      label: insurance.payer.name,
    }))
  const handleChangeInsurance = value => {
    const insurance = insurances.find(({ id }) => `${id}` === value)
    onChange({
      insuranceId: value,
      subscriber: {
        ...values.subscriber,
        relationship: insurance?.relationship || '',
      },
    })
  }

  const handleChangeSubscriber = value =>
    onChange({
      ...values,
      subscriber: { ...values.subscriber, ...value },
    })

  useEffect(() => {
    if (insurances.length === 1) {
      const [insurance] = insurances
      onChange({
        insuranceId: insurance.id,
        subscriber: {
          ...values.subscriber,
          relationship: insurance.relationship || '',
        },
      })
    }
  }, [insurances])

  return (
    <Grid container spacing={2}>
      <Grid item xs={isEdit ? 6 : 8}>
        <SelectField
          fullWidth
          noMargin
          label="Insurance"
          name="insurance"
          placeholder="Choose an option"
          options={insuranceOptions(insurances)}
          value={values.insuranceId}
          error={error?.insuranceId}
          onChange={handleChangeInsurance}
          disabled={isEdit}
        />
      </Grid>

      <Grid item xs={12}>
        <Patient patient={patient} />
      </Grid>

      {values?.insuranceId && (
        <Subscriber
          editAllowed={true}
          isEdit={isEdit}
          values={values}
          error={error}
          onChange={handleChangeSubscriber}
        />
      )}
    </Grid>
  )
}

export default Form
