import styled from 'styled-components'
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown'

export const Wrapper = styled.div`
  min-width: 100px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
`

export const FakeInput = styled.div`
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? 'rgba(0, 0, 0, 0.26)' : theme.primary.main};
  border-radius: 4px;
  padding: 6px 8px 6px 14px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  ${({ open }) =>
    open &&
    `
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  `}
`

export const ArrowDown = styled(ArrowDownIcon)`
  color: ${({ theme, disabled }) =>
    disabled ? 'rgba(0, 0, 0, 0.26)' : theme.gray.dark};
`

export const CalendarWrapper = styled.div`
  position: absolute;
  border: 1px solid ${({ theme }) => theme.primary.main};
  background: ${({ theme }) => theme.background.default};
  z-index: 9999; /* Ensures it is above modal and other elements */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: 9998; /* Below the calendar but above other elements */
`
