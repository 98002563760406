import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@/v2/components/common/Divider'
import { Wrapper } from './styles'

const HealthPlan = ({ healthplan }) => {
  if (!healthplan)
    return (
      <Typography variant="span" fontWeight={200} textAlign="center">
        No Plan data found
      </Typography>
    )

  return (
    <Wrapper>
      <Grid item xs={12} mb={2}>
        <Divider margin="20px 0px" />
        <Grid item xs={12} mb={2}>
          <Typography variant="h4" fontWeight={500}>
            Plan:
          </Typography>
        </Grid>
        <Typography variant="h4" fontWeight={500}>
          Group Name:{' '}
          <Typography variant="span" fontWeight={200}>
            {healthplan?.plan?.groupName || '-'}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Group Number:{' '}
          <Typography variant="span" fontWeight={200}>
            {healthplan?.plan?.groupNumber || '-'}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Subscriber ID:{' '}
          <Typography variant="span" fontWeight={200}>
            {healthplan?.plan?.subscriberId || '-'}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Coverage Indicator:{' '}
          <Typography variant="span" fontWeight={200}>
            {healthplan?.plan?.coverageIndicator || '-'}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Effective Date From:{' '}
          <Typography variant="span" fontWeight={200}>
            {healthplan?.plan?.effectiveDateFrom || '-'}
          </Typography>
        </Typography>
      </Grid>
      <Divider margin="20px 0px" />
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Insurance:
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Name:{' '}
          <Typography variant="span" fontWeight={200}>
            {healthplan?.insurance?.payer?.name}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Code:{' '}
          <Typography variant="span" fontWeight={200}>
            {healthplan?.insurance?.payer?.code}
          </Typography>
        </Typography>
      </Grid>
      <Divider margin="20px 0px" />
    </Wrapper>
  )
}

export default HealthPlan
