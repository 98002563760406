import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import SelectField from '@/v2/components/common/SelectField'
import TextField from '@/v2/components/common/TextField'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import Box from '@mui/material/Box'
import { useProviders } from '@/v2/hooks/insurance/useEligibilities'
import { providerKindOptions, specialtyOptions } from '@/v2/constants/insurance'
import IconButton from '@/v2/components/common/IconButton'
import {
  DeleteOutline,
  EditOutlined,
  SaveOutlined,
  CancelOutlined,
} from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

const DEFAULT_PROVIDER_DATA = {
  provider: '',
  taxId: '',
  npi: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  kind: '',
  type: '',
  specialty: 'ORTHO',
  taxonomy: '',
  npiType: '',
}

const formatProviderOptions = (providers = []) =>
  providers.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

const Provider = ({
  values = DEFAULT_PROVIDER_DATA,
  error = {},
  onChange,
  onSave,
  isAdding,
  isEdit,
  onDelete,
  onEdit,
  onCancel,
  taxonomyCodes,
  provider,
}) => {
  const [taxonomies, setTaxonomies] = useState(taxonomyCodes || [])
  const [selectedProvider, setSelectedProvider] = useState(
    DEFAULT_PROVIDER_DATA
  )
  const [isManualEntry, setIsManualEntry] = useState(false)
  const [backupProviderData, setBackupProviderData] = useState({})
  const { providers } = useProviders()
  const theme = useTheme()

  useEffect(() => {
    setTaxonomies(taxonomyCodes || [])
  }, [taxonomyCodes])

  useEffect(() => {
    if (provider) {
      const addressData = provider.addresses?.[0] || {}
      setSelectedProvider({
        provider: provider.provider || '',
        firstName: provider.firstName || '',
        lastName: provider.lastName || '',
        organizationName: provider.organizationName || '',
        taxId: provider.taxId || '',
        npi: provider.npi || '',
        address1: addressData.address1 || '',
        address2: addressData.address2 || '',
        city: addressData.city || '',
        state: addressData.state || '',
        zipcode: addressData.zipcode || '',
        kind: addressData.kind || '',
        type: provider.kind || '',
        specialty: provider.specialty || 'ORTHO',
        taxonomy: provider.taxonomy || '',
        npiType:
          provider.providerType === 'clinic' ? 'organization' : 'individual',
      })
    }
  }, [provider])

  useEffect(() => {
    if (isAdding) {
      setSelectedProvider({ ...DEFAULT_PROVIDER_DATA, ...values })
    }
  }, [values, isAdding])

  const setProviderData = data => {
    setSelectedProvider(prev => ({ ...prev, ...data }))
    onChange({ ...selectedProvider, ...data })
  }

  const handleCancel = () => {
    if (isAdding) {
      setSelectedProvider(DEFAULT_PROVIDER_DATA)
    } else {
      const addressData = provider.addresses?.[0] || {}
      setSelectedProvider({
        provider: provider.provider || '',
        firstName: provider.firstName || '',
        lastName: provider.lastName || '',
        organizationName: provider.organizationName || '',
        taxId: provider.taxId || '',
        npi: provider.npi || '',
        address1: addressData.address1 || '',
        address2: addressData.address2 || '',
        city: addressData.city || '',
        state: addressData.state || '',
        zipcode: addressData.zipcode || '',
        kind: addressData.kind || '',
        type: provider.kind || '',
        specialty: provider.specialty || 'ORTHO',
        taxonomy: provider.taxonomy || '',
        npiType: provider.npiType || '',
      })
    }
    onCancel()
  }

  const handleSelectProvider = value => {
    if (isManualEntry) return
    const selected = providers.find(p => `${p.id}` === value)
    if (selected) {
      const taxonomyOptions =
        selected.taxonomies?.map(({ code, specialty, category }) => ({
          value: code,
          label: `${code} - ${specialty || category || ''}`,
        })) || []
      setTaxonomies(taxonomyOptions)
      const addressData = selected.addresses?.[0] || {}
      setProviderData({
        provider: selected.id,
        ...(selected.providerType === 'clinic'
          ? { organizationName: selected.name }
          : { firstName: selected.firstName, lastName: selected.lastName }),
        taxId: selected.taxId || '',
        npi: selected.npi || '',
        type: selected.type || '',
        specialty: selected.specialty || 'ORTHO',
        taxonomy: selected.taxonomy || '',
        npiType:
          selected.providerType === 'clinic' ? 'organization' : 'individual',
        ...addressData,
      })
    }
  }

  const handleFieldChange = field => event => {
    const value = event.target ? event.target.value : event
    setProviderData({ [field]: value })
  }

  const handleSave = () => {
    onSave(selectedProvider)
  }

  const handleManualEntryToggle = () => {
    if (isManualEntry) {
      setSelectedProvider(backupProviderData)
      setBackupProviderData({})
    } else {
      setBackupProviderData(selectedProvider)
      setProviderData(DEFAULT_PROVIDER_DATA)
    }
    setIsManualEntry(prev => !prev)
  }

  const renderTextField = (name, label, placeholder, isDisabled) => (
    <TextField
      fullWidth
      name={name}
      label={label}
      placeholder={placeholder}
      value={selectedProvider[name] || ''}
      error={error[name]}
      onChange={handleFieldChange(name)}
      disabled={isDisabled}
    />
  )

  return (
    <Box display="flex" alignItems="center" width="100%" gap={2}>
      <Grid container spacing={2} flex={1} alignItems="center">
        {isAdding && (
          <Grid item xs={12}>
            {!isManualEntry && (
              <SelectField
                fullWidth
                noMargin
                name="provider"
                label="Select provider from eligibility"
                placeholder="Choose an option"
                options={formatProviderOptions(providers)}
                value={selectedProvider.provider}
                onChange={handleSelectProvider}
                disabled={isManualEntry}
              />
            )}
            <Button
              variant="text"
              color={isManualEntry ? 'error' : 'link'}
              onClick={handleManualEntryToggle}
              sx={{ marginTop: '5px' }}
            >
              {isManualEntry
                ? 'Cancel Manual Entry'
                : 'Enter Provider Manually'}
            </Button>
          </Grid>
        )}

        {(isManualEntry || !!selectedProvider.provider || !isAdding) && (
          <>
            <Grid item xs={3}>
              <SelectField
                fullWidth
                noMargin
                name="npiType"
                label="NPI Type"
                options={[
                  { value: 'individual', label: 'Individual' },
                  { value: 'organization', label: 'Organization' },
                ]}
                value={selectedProvider.npiType}
                error={error.npiType}
                onChange={handleFieldChange('npiType')}
                placeholder="Choose an option"
                disabled={!isEdit}
              />
            </Grid>
            {selectedProvider.npiType === 'organization' && (
              <Grid item xs={3}>
                {renderTextField(
                  'organizationName',
                  'Organization Name',
                  'Organization Name',
                  !isEdit
                )}
              </Grid>
            )}

            {selectedProvider.npiType === 'individual' && (
              <>
                <Grid item xs={3}>
                  {renderTextField(
                    'firstName',
                    'First Name',
                    'First Name',
                    !isEdit
                  )}
                </Grid>
                <Grid item xs={3}>
                  {renderTextField(
                    'lastName',
                    'Last Name',
                    'Last Name',
                    !isEdit
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={3}>
              <SelectField
                fullWidth
                noMargin
                name="type"
                label="Kind of provider"
                options={providerKindOptions}
                value={selectedProvider.type}
                error={error.type}
                onChange={handleFieldChange('type')}
                placeholder="Choose an option"
                disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectField
                fullWidth
                noMargin
                name="specialty"
                label="Specialty"
                options={specialtyOptions}
                value={selectedProvider.specialty}
                error={error.specialty}
                onChange={handleFieldChange('specialty')}
                placeholder="Choose an option"
                disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectField
                fullWidth
                noMargin
                name="taxonomy"
                label="Taxonomy"
                options={taxonomies}
                value={selectedProvider.taxonomy}
                error={error.taxonomy}
                onChange={handleFieldChange('taxonomy')}
                placeholder="Choose an option"
                disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={3}>
              {renderTextField('taxId', 'Tax ID', 'Tax ID', !isAdding)}
            </Grid>
            <Grid item xs={3}>
              {renderTextField('npi', 'NPI', 'NPI', !isAdding)}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight={400}>
                Provider Address
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {renderTextField('address1', 'Address', 'Address', !isEdit)}
            </Grid>
            <Grid item xs={3}>
              {renderTextField('address2', 'Address 2', 'Address 2', !isEdit)}
            </Grid>
            <Grid item xs={3}>
              {renderTextField('city', 'City', 'City', !isEdit)}
            </Grid>
            <Grid item xs={3}>
              {renderTextField('state', 'State', 'State', !isEdit)}
            </Grid>
            <Grid item xs={3}>
              {renderTextField('zipcode', 'Zipcode', 'Zipcode', !isEdit)}
            </Grid>
            <Grid item xs={3}>
              <SelectField
                fullWidth
                noMargin
                name="kind"
                label="Kind"
                options={[
                  { label: 'Primary', value: 'primary' },
                  { label: 'Secondary', value: 'secondary' },
                ]}
                value={selectedProvider.kind}
                error={error.kind}
                onChange={handleFieldChange('kind')}
                placeholder="Choose an option"
                disabled={!isEdit}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Box display="flex" flexDirection="column" gap={1} alignSelf="center">
        {isEdit ? (
          <>
            <IconButton
              title="Save Provider"
              aria-label="Save Provider"
              bordercolor="silver"
              sx={{
                background: theme.palette.silver.main,
                marginBottom: '4px',
              }}
              onClick={handleSave}
            >
              <SaveOutlined color="action" />
            </IconButton>
            <IconButton
              title="Cancel Changes"
              aria-label="Cancel Changes"
              bordercolor="silver"
              sx={{
                background: theme.palette.silver.main,
                marginBottom: '4px',
              }}
              onClick={handleCancel}
            >
              <CancelOutlined color="error" />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              title="Edit Provider"
              bordercolor="silver"
              sx={{
                background: theme.palette.silver.main,
                marginBottom: '4px',
              }}
              onClick={onEdit}
            >
              <EditOutlined color="action" />
            </IconButton>
            <IconButton
              title="Delete Provider"
              aria-label="Delete Provider"
              bordercolor="silver"
              sx={{
                background: theme.palette.silver.main,
                marginBottom: '4px',
              }}
              onClick={onDelete}
            >
              <DeleteOutline color="action" />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  )
}

export default Provider
