import React from 'react'
import RealTime from './RealTime'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { useQueryClient } from '@tanstack/react-query'

const RTEligibilities = () => {
  const queryClient = useQueryClient()
  const { patient } = usePatientContext()

  const handleEvent = ({ action, subject, data }) => {
    queryClient.setQueryData(['ShowEligibility', `${data.id}`], () => data)

    queryClient.invalidateQueries(['ShowEligibility', `${data.id}`])

    queryClient.setQueryData(['Eligibilities', `${patient.id}`], oldData => {
      if (!oldData) return [data]
      const index = oldData.findIndex(item => item.id === data.id)
      if (index !== -1) {
        return oldData.map(item => (item.id === data.id ? data : item))
      }
      if (action === 'created') {
        return [...oldData, data]
      }
      return oldData
    })
  }

  return (
    <RealTime
      channel="PatientProfile::Insurance::EligibilitiesChannel"
      onEvent={handleEvent}
      patientId={patient.id}
    />
  )
}

export default RTEligibilities
