import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import styled from 'styled-components'
import { format, isValid } from 'date-fns'
import SVGInline from 'react-svg-inline'
import ChevronLeft from '#/assets/images/v2/chevron-left.svg?inline'
import ChevronRight from '#/assets/images/v2/chevron-right.svg?inline'
import Typography from '@/v2/components/common/Typography'

const CalendarWrapper = styled.div`
  margin: 7px 14px 13px 12px;
  justify-content: center;
  display: flex;
`

const NavigationWrapper = styled.div`
  display: flex;
  gap: 3.5px;
`

const NavigationButton = styled(SVGInline)`
  & > svg {
    height: 12px;
    display: flex;
    margin: 0 auto;
  }
`

const BasicCalendar = ({ date, onSelect, onClickMonth }) => {
  const [day, setDay] = useState(new Date())

  useEffect(() => {
    const selectedDate = date && (date[0] || date)
    if (!isValid(selectedDate)) return
    setDay(date)
  }, [date, setDay, isValid])

  return (
    <CalendarWrapper>
      <Calendar
        locale="en"
        calendarType="gregory"
        value={day}
        prevLabel={<NavigationButton svg={ChevronLeft} />}
        nextLabel={<NavigationButton svg={ChevronRight} />}
        navigationLabel={({ date }) => (
          <NavigationWrapper>
            <Typography
              variant="h2"
              onClick={() => onClickMonth && onClickMonth(date)}
              sx={{ cursor: onClickMonth ? 'pointer' : 'default' }}
            >
              {format(date, 'MMMM')}
            </Typography>
            <Typography variant="h2">{format(date, ' yyyy')}</Typography>
          </NavigationWrapper>
        )}
        onChange={date => onSelect(date)}
        // onActiveStartDateChange={handleChange}
      />
    </CalendarWrapper>
  )
}

export default BasicCalendar
