import React, { useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import MiniCalendar from '@/v2/components/common/MiniCalendar'
import Label from '@/v2/components/common/FieldLabel'
import Typography from '@/v2/components/common/Typography'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import BasicCalendar from '@/v2/components/common/BasicCalendar'

import {
  Wrapper,
  FakeInput,
  ArrowDown,
  CalendarWrapper,
  Backdrop,
} from './styles'

const SelectCalendar = ({
  label,
  value,
  error,
  disabled = false,
  hasOccupancy = true,
  labelFormat = 'MM/dd/yy',
  onChange,
  isBasicCalendar = false,
}) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState(null)
  const [fakeInputRef, setFakeInputRef] = useState(null) // Reference for positioning
  const [position, setPosition] = useState({ top: 0, left: 0 })

  useEffect(() => {
    if (value && typeof value === 'string') {
      setDate(getDateTimeWithoutTimezone(value))
    } else {
      setDate(value)
    }
  }, [value])

  const toggleCalendar = useCallback(() => {
    if (!disabled && fakeInputRef) {
      const rect = fakeInputRef.getBoundingClientRect()
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      })
      setOpen(prev => !prev)
    }
  }, [disabled, fakeInputRef])

  const closeCalendar = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <Wrapper>
        <Label>{label}</Label>
        <FakeInput
          ref={setFakeInputRef}
          theme={theme.palette}
          open={open ? 1 : 0}
          disabled={disabled ? 1 : 0}
          onClick={toggleCalendar}
        >
          <Typography
            fontSize="14px"
            lineHeight="14px"
            color={disabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit'}
          >
            {date ? format(date, labelFormat) : labelFormat.toLowerCase()}
          </Typography>
          <ArrowDown theme={theme.palette} disabled={disabled ? 1 : 0} />
        </FakeInput>
        {error && <Typography variant="error">{error}</Typography>}
      </Wrapper>
      {open &&
        createPortal(
          <>
            <Backdrop onClick={closeCalendar} />
            <CalendarWrapper
              theme={theme.palette}
              style={{ top: position.top, left: position.left }}
            >
              {isBasicCalendar ? (
                <BasicCalendar
                  date={date}
                  onSelect={selectedDate => {
                    setDate(selectedDate)
                    closeCalendar()
                    if (onChange) onChange(selectedDate)
                  }}
                />
              ) : (
                <MiniCalendar
                  isGrayWhenFull
                  date={date}
                  hasOccupancy={hasOccupancy}
                  onSelect={selectedDate => {
                    setDate(selectedDate)
                    closeCalendar()
                    if (onChange) onChange(selectedDate)
                  }}
                />
              )}
            </CalendarWrapper>
          </>,
          document.body
        )}
    </>
  )
}

export default SelectCalendar
