import { useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import {
  useCancelClaim,
  useApproveClaim,
  useDenyClaim,
  useSubmitClaim,
  useValidateClaim,
} from '@/v2/hooks/insurance/useClaims'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import useModals from '@/v2/hooks/useModals'

const useClaimOptions = (
  status,
  claimId,
  patientId,
  insuranceId = '',
  claim = {},
  patient = {}
) => {
  const theme = useTheme()
  const noViewStatus = ['pending', 'invalidated', 'answered']
  const noCancelStatus = ['approved', 'denied', 'cancelled', 'failed']
  const canEditStatus = ['pending', 'invalidated', 'answered']
  const outlinedStatus = ['pending', 'submitted', 'validated']
  const { cancelClaim } = useCancelClaim(claimId, patientId)
  const { approveClaim } = useApproveClaim(claimId, patientId)
  const { denyClaim } = useDenyClaim(claimId, patientId)
  const { submitClaim } = useSubmitClaim(claimId, patientId)
  const { validateClaim } = useValidateClaim(claimId, patientId)
  const { onOpenSnackbar } = useSnackbarContext()
  const { goTo } = useModals()

  const options = useMemo(
    () =>
      [
        canEditStatus.includes(status) && {
          label: 'Edit',
          onClick: () => {
            goTo(`v2/patient/${patientId}/claim/edit`, {
              modalType: 'edit',
              patientId,
              isEdit: true,
              insuranceId,
              claim,
              patient,
            })
          },
        },
        status === 'pending' && {
          label: 'Validate',
          onClick: async () => {
            const { error } = await validateClaim()
            if (!error) {
              onOpenSnackbar('Claim validated', 'success')
            }
          },
        },
        status === 'invalidated' && {
          label: 'Submit',
          onClick: async () => {
            const { error } = await submitClaim()
            if (!error) {
              onOpenSnackbar('Claim submitted', 'success')
            }
          },
        },
        ...(status === 'answered'
          ? [
              {
                label: 'Set as Approved',
                onClick: async () => {
                  const { error } = await approveClaim()
                  if (!error) {
                    onOpenSnackbar('Claim approved', 'success')
                  }
                },
              },
              {
                label: 'Set as Denied',
                onClick: async () => {
                  const { error } = await denyClaim()
                  if (!error) {
                    onOpenSnackbar('Claim denied', 'success')
                  }
                },
              },
            ]
          : []),
        !noViewStatus.includes(status) && {
          label: 'View',
          onClick: () => {
            goTo(`v2/patient/${patientId}/claim/view`, {
              modalType: 'view',
              patientId,
              claimId,
              insuranceId,
            })
          },
        },
        !noCancelStatus.includes(status) && {
          label: 'Cancel',
          onClick: async () => {
            const { error } = await cancelClaim()
            if (!error) {
              onOpenSnackbar('Claim cancelled', 'success')
            }
          },
        },
      ].filter(Boolean),
    [status]
  )

  const statusColors = useMemo(
    () => ({
      pending: theme.palette.grey.A700,
      validating: theme.palette.grey.A700,
      validated: theme.palette.warning.main,
      invalidated: theme.palette.error.main,
      submitting: theme.palette.warning.main,
      submitted: theme.palette.success.main,
      answered: theme.palette.success.main,
      approved: theme.palette.success.main,
      denied: theme.palette.error.main,
      cancelled: theme.palette.error.main,
      failed: theme.palette.error.main,
    }),
    [theme]
  )

  const getLabel = status => {
    if (status === 'validated') return 'Valid and waiting to send'
    if (status === 'submitted') return 'Sent'
    return status
  }

  return { options, statusColors, outlinedStatus, getLabel }
}

export default useClaimOptions
