import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import * as Yup from 'yup'
import {
  DeleteOutline,
  EditOutlined,
  SaveOutlined,
  CancelOutlined,
} from '@mui/icons-material'
import Button from '@/v2/components/common/Button'
import IconButton from '@/v2/components/common/IconButton'
import Typography from '@/v2/components/common/Typography'
import TextField from '@/v2/components/common/TextField'
import Box from '@mui/material/Box'
import SelectField from '@/v2/components/common/SelectField'
import { imageOrientations, imageTypeCode } from '../../utils'
import {
  useRemoveClaimAttachment,
  useCreateClaimAttachment,
  useCreateClaimAttachmentImages,
  useRemoveClaimAttachmentImages,
  useUpdateClaimAttachment,
  useUpdateClaimAttachmentImages,
} from '@/v2/hooks/insurance/claim-subforms/useClaimAttachments'
import { CircularProgress } from '@mui/material'

const attachmentSchema = Yup.object().shape({
  narrative: Yup.string().required('Narrative is required'),
})

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 16px;
`

const Attachments = ({ values = {}, onChange, claimId }) => {
  const [attachments, setAttachments] = useState(values?.attachments || [])
  const [editingAttachmentId, setEditingAttachmentId] = useState(null)
  const [isAdding, setIsAdding] = useState(false)
  const [initialAttachmentData, setInitialAttachmentData] = useState({
    narrative: '',
    attachments: [],
  })
  const [validationErrors, setValidationErrors] = useState({})

  // Mutation hooks with isLoading flags
  const {
    removeClaimAttachment,
    isLoading: isDeletingAttachment,
  } = useRemoveClaimAttachment(claimId)
  const {
    createClaimAttachment,
    isLoading: isCreatingAttachment,
  } = useCreateClaimAttachment(claimId)
  const {
    updateClaimAttachment,
    isLoading: isUpdatingAttachment,
  } = useUpdateClaimAttachment(claimId)

  useEffect(() => {
    if (values.attachments) setAttachments(values?.attachments || [])
  }, [values])

  const handleDeleteAttachmentSet = async attachmentId => {
    if (isDeletingAttachment) return // Prevent multiple requests
    try {
      await removeClaimAttachment(attachmentId)
      const updatedAttachments = attachments.filter(
        ({ id }) => id !== attachmentId
      )
      setAttachments(updatedAttachments)
      onChange?.({ ...values, attachments: updatedAttachments })
    } catch (error) {
      console.error('Error deleting attachment:', error)
    }
  }

  const handleAddAttachmentSet = () => {
    setIsAdding(true)
    setEditingAttachmentId(null)
    setInitialAttachmentData({ narrative: '', attachments: [] })
    setValidationErrors({})
  }

  const handleSaveAttachmentSet = async () => {
    try {
      if (isCreatingAttachment || isUpdatingAttachment) return // Prevent multiple requests

      await attachmentSchema.validate(initialAttachmentData, {
        abortEarly: false,
      })
      setValidationErrors({})

      if (isAdding) {
        const formData = new FormData()
        formData.append(
          'attachment[narrative]',
          initialAttachmentData.narrative
        )
        initialAttachmentData?.images?.forEach(file => {
          formData.append(
            'attachment[images_attributes][][orientation_type]',
            file.orientationType
          )
          formData.append(
            'attachment[images_attributes][][type_code]',
            file.typeCode
          )
          formData.append('attachment[images_attributes][][date]', file.date)
          formData.append('attachment[images_attributes][][file]', file.file)
        })
        const res = await createClaimAttachment(formData)
        if (!res.error) {
          const newAttachment = { ...initialAttachmentData, id: res.id }
          const newAttachments = [...attachments, newAttachment]
          setAttachments(newAttachments)
          onChange?.({ ...values, attachments: newAttachments })
          setIsAdding(false)
          setInitialAttachmentData({ narrative: '', attachments: [] })
        }
        return
      }

      if (editingAttachmentId) {
        const data = {
          body: {
            attachment: {
              narrative: initialAttachmentData.narrative,
            },
          },
          attachmentId: editingAttachmentId,
        }
        const res = await updateClaimAttachment(data)
        if (!res.error) {
          const updatedAttachments = attachments.map(attachment =>
            attachment.id === editingAttachmentId
              ? { ...attachment, narrative: initialAttachmentData.narrative }
              : attachment
          )
          setAttachments(updatedAttachments)
          onChange?.({ ...values, attachments: updatedAttachments })
          setEditingAttachmentId(null)
          setInitialAttachmentData({ narrative: '', attachments: [] })
        }
      }
    } catch (validationErrors) {
      const errors = {}
      validationErrors.inner.forEach(err => {
        errors[
          `${isAdding ? 'newAttachment' : editingAttachmentId}.${err.path}`
        ] = err.message
      })
      setValidationErrors(errors)
    }
  }

  const handleEditAttachmentSet = attachmentId => {
    setEditingAttachmentId(attachmentId)
    setIsAdding(false)
    const attachmentToEdit = attachments.find(set => set.id === attachmentId)
    setInitialAttachmentData(attachmentToEdit)
    setValidationErrors({})
  }

  const handleCancelEdit = () => {
    setEditingAttachmentId(null)
    setIsAdding(false)
    setInitialAttachmentData({ narrative: '', attachments: [] })
    setValidationErrors({})
  }

  return (
    <>
      <Grid item xs={12} sx={{ marginTop: '12px' }}>
        <Typography variant="h5" bold>
          Attachments
        </Typography>
      </Grid>
      {attachments.map((attachmentSet, index) =>
        attachmentSet && attachmentSet.id ? (
          <Grid item key={attachmentSet.id} xs={12}>
            <Wrapper>
              <AttachmentItem
                isEdit={editingAttachmentId === attachmentSet.id}
                showLabel={true}
                values={
                  editingAttachmentId === attachmentSet.id
                    ? initialAttachmentData
                    : attachmentSet
                }
                errorAffixe={
                  editingAttachmentId === attachmentSet.id
                    ? editingAttachmentId
                    : `attachmentSet[${index}]`
                }
                error={validationErrors}
                onChange={value => setInitialAttachmentData(value)}
                allowFileEdit={editingAttachmentId === attachmentSet.id}
                attachmentId={attachmentSet.id}
                claimId={claimId}
              />
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="16px"
                mt={index === 0 ? '25px' : 0}
              >
                {editingAttachmentId === attachmentSet.id ? (
                  <>
                    <IconButton
                      onClick={handleSaveAttachmentSet}
                      disabled={isCreatingAttachment || isUpdatingAttachment}
                    >
                      {isCreatingAttachment || isUpdatingAttachment ? (
                        <CircularProgress size={20} />
                      ) : (
                        <SaveOutlined color="action" />
                      )}
                    </IconButton>
                    <IconButton onClick={handleCancelEdit}>
                      <CancelOutlined color="error" />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      onClick={() => handleEditAttachmentSet(attachmentSet.id)}
                    >
                      <EditOutlined color="action" />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        handleDeleteAttachmentSet(attachmentSet.id)
                      }
                      disabled={isDeletingAttachment}
                    >
                      {isDeletingAttachment ? (
                        <CircularProgress size={20} />
                      ) : (
                        <DeleteOutline color="error" />
                      )}
                    </IconButton>
                  </>
                )}
              </Box>
            </Wrapper>
          </Grid>
        ) : null
      )}

      <Grid item xs={12}>
        {!isAdding && editingAttachmentId === null ? (
          <Button
            fullWidth
            variant="outlined"
            onClick={handleAddAttachmentSet}
            disabled={isCreatingAttachment || isUpdatingAttachment}
          >
            <Typography bold variant="label">
              + Add An Attachment
            </Typography>
          </Button>
        ) : null}
      </Grid>
      {isAdding && (
        <Grid item xs={12}>
          <Wrapper>
            <AttachmentItem
              values={initialAttachmentData}
              errorAffixe="newAttachment"
              error={validationErrors}
              isEdit={true}
              onChange={newData => setInitialAttachmentData(newData)}
              allowFileEdit={true}
              isAdding={isAdding}
              claimId={claimId}
            />
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="16px"
              mt="20px"
            >
              <IconButton
                onClick={handleSaveAttachmentSet}
                disabled={isCreatingAttachment}
              >
                {isCreatingAttachment ? (
                  <CircularProgress size={20} />
                ) : (
                  <SaveOutlined color="action" />
                )}
              </IconButton>
              <IconButton onClick={handleCancelEdit}>
                <CancelOutlined color="error" />
              </IconButton>
            </Box>
          </Wrapper>
        </Grid>
      )}
    </>
  )
}

const AttachmentItem = ({
  isEdit = false,
  isAdding = false,
  showLabel = true,
  values = {},
  error = {},
  errorAffixe,
  onChange,
  allowFileEdit = false,
  attachmentId,
  claimId,
}) => {
  const [editingFileIndex, setEditingFileIndex] = useState(null)
  const [addingFileIndex, setAddingFileIndex] = useState(null)
  const [allAttachments, setAllAttachments] = useState(values?.images || [])
  const {
    createClaimAttachmentImages,
    isLoading: isLoadingCreate,
  } = useCreateClaimAttachmentImages(attachmentId, claimId)
  const { updateClaimAttachmentImages } = useUpdateClaimAttachmentImages(
    attachmentId,
    claimId
  )
  const { removeClaimAttachmentImage } = useRemoveClaimAttachmentImages(
    attachmentId,
    claimId
  )

  useEffect(() => {
    setAllAttachments(values?.images || [])
  }, [values.images])

  const handleFileAdd = async file => {
    const newFileIndex = allAttachments.length
    setAddingFileIndex(newFileIndex)

    const newFile = {
      id: Date.now(),
      isNew: true,
      file,
      name: file.name,
      date: new Date().toISOString().split('T')[0],
      type: 'B4',
      orientationType: 'LEFT',
    }
    const updatedFiles = [...allAttachments, newFile]
    setAllAttachments(updatedFiles)
    onChange({ ...values, images: updatedFiles })
  }

  const handleFileChange = (index, field, value) => {
    const updatedFiles = allAttachments.map((file, i) =>
      i === index ? { ...file, [field]: value } : file
    )
    setAllAttachments(updatedFiles)
    onChange({ ...values, images: updatedFiles })
  }

  const handleFileSave = async (fileParam, index) => {
    const isNewFile = fileParam.isNew
    const formData = new FormData()
    formData.append('image[type_code]', fileParam.typeCode)
    formData.append('image[orientation_type]', fileParam.orientationType)
    formData.append('image[date]', fileParam.date)
    formData.append('image[file]', fileParam.file)

    try {
      if (isNewFile) {
        const res = await createClaimAttachmentImages(formData)
        if (!res.error && res.id) {
          const updatedFiles = allAttachments.map((file, i) =>
            i === index ? { ...file, id: res.id, isNew: false } : file
          )
          setAllAttachments(updatedFiles)
          onChange?.({ ...values, images: updatedFiles })
          if (addingFileIndex === index) setAddingFileIndex(null)
        }
      } else {
        const body = {
          imageId: fileParam.id,
          data: fileParam,
        }
        const res = await updateClaimAttachmentImages(body)
        if (!res.error) {
          setEditingFileIndex(null)
        }
      }
    } catch (error) {
      console.error(`Error in ${isNewFile ? 'POST' : 'PUT'} operation:`, error)
    }
  }

  const handleFileRemove = async index => {
    const file = allAttachments[index]
    if (file.isNew) {
      const updatedFiles = allAttachments.filter((_, i) => i !== index)
      setAllAttachments(updatedFiles)
      setAddingFileIndex(null)
      onChange({ ...values, images: updatedFiles })
    } else {
      const res = await removeClaimAttachmentImage(file.id)
      if (!res.error) {
        const updatedFiles = allAttachments.filter((_, i) => i !== index)
        setAllAttachments(updatedFiles)
        onChange({ ...values, images: updatedFiles })
      }
    }
  }

  const handleCancelEdit = index => {
    if (addingFileIndex === index) {
      const updatedFiles = allAttachments.filter((_, i) => i !== index)
      setAllAttachments(updatedFiles)
      onChange({ ...values, images: updatedFiles })
      setAddingFileIndex(null)
    } else if (editingFileIndex === index) {
      setEditingFileIndex(null)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="narrative"
          label={showLabel ? 'Narrative' : ''}
          placeholder="Type Narrative"
          value={values.narrative || ''}
          error={error[`${errorAffixe}.narrative`]}
          disabled={!isEdit}
          onChange={value => onChange({ ...values, narrative: value })}
        />
      </Grid>
      {allAttachments?.map((file, index) => (
        <React.Fragment key={file.id || index}>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label={showLabel && index === 0 ? 'File Name' : ''}
              value={file.name || file.filename || 'No File Name'}
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label={showLabel && index === 0 ? 'Date' : ''}
              type="date"
              value={file.date || ''}
              onChange={value => handleFileChange(index, 'date', value)}
              disabled={
                addingFileIndex !== index &&
                editingFileIndex !== index &&
                (!allowFileEdit || editingFileIndex !== index)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <SelectField
              fullWidth
              name="typeCode"
              label={showLabel && index === 0 ? 'File Type' : ''}
              placeholder="Choose an option"
              options={imageTypeCode}
              value={file.typeCode}
              onChange={value => handleFileChange(index, 'typeCode', value)}
              disabled={
                addingFileIndex !== index &&
                editingFileIndex !== index &&
                (!allowFileEdit || editingFileIndex !== index)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <SelectField
              fullWidth
              name="orientationType"
              label={showLabel && index === 0 ? 'Orientation' : ''}
              placeholder="Choose an option"
              options={imageOrientations}
              value={file.orientationType}
              onChange={value =>
                handleFileChange(index, 'orientationType', value)
              }
              disabled={
                addingFileIndex !== index &&
                editingFileIndex !== index &&
                (!allowFileEdit || editingFileIndex !== index)
              }
            />
          </Grid>
          <Grid item xs={2} display="flex" alignItems="center" gap="16px">
            {addingFileIndex === index || editingFileIndex === index ? (
              <>
                {!isAdding && (
                  <IconButton
                    onClick={() => handleFileSave(file, index)}
                    disabled={isLoadingCreate}
                  >
                    {isLoadingCreate ? (
                      <CircularProgress size={20} />
                    ) : (
                      <SaveOutlined color="action" />
                    )}
                  </IconButton>
                )}

                <IconButton
                  onClick={() => handleCancelEdit(index)}
                  disabled={isLoadingCreate}
                >
                  <CancelOutlined color="error" />
                </IconButton>
              </>
            ) : (
              allowFileEdit && (
                <IconButton
                  onClick={() => setEditingFileIndex(index)}
                  disabled={isLoadingCreate}
                >
                  <EditOutlined color="action" />
                </IconButton>
              )
            )}
            <IconButton
              onClick={() => handleFileRemove(index)}
              disabled={isLoadingCreate}
            >
              <DeleteOutline color="error" />
            </IconButton>
          </Grid>
        </React.Fragment>
      ))}
      {isEdit && (
        <Grid item xs={12}>
          <input
            type="file"
            onChange={e => handleFileAdd(e.target.files[0])}
            accept="*"
            style={{ display: 'none' }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button variant="outlined" component="span">
              + Add File
            </Button>
          </label>
        </Grid>
      )}
    </Grid>
  )
}

export default Attachments
