import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { useShowEligibility } from '@/v2/hooks/insurance/useEligibilities'
import { ContentWrapper, AccordionStyled } from './show-lists/styles'
import ActiveCoverage from './show-lists/ActiveCoverage'
import EligibilityItem from './show-lists/EligibilityItem'
import PatientData from './show-lists/PatientData'
import SubscriberData from './show-lists/SubscriberData'
import HealthPlan from './show-lists/HealthPlan'
import Grid from '@mui/material/Grid'
import Button from '@/v2/components/common/Button'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

const Show = ({ eligibility, patient, onProceed }) => {
  const theme = useTheme()
  const [activeCoverage, setActiveCoverage] = useState(null)
  const [expandedPanel, setExpandedPanel] = useState('patientData')
  const [coInsurances, setCoInsurances] = useState([])
  const [deductibles, setDeductibles] = useState([])
  const [limitations, setLimitations] = useState([])
  const [patientData, setPatientData] = useState(null)
  const [subscriberData, setSubscriberData] = useState(null)
  const [healthPlan, setHealthPlan] = useState(null)
  const [errors, setErrors] = useState(null)
  const { eligibilityInfo, isLoading, isError } = useShowEligibility(
    eligibility?.insurance?.id,
    eligibility?.id
  )
  const history = useHistory()

  const statusColors = {
    failed: theme.palette.error.main,
    created: theme.palette.success.main,
    requested: theme.palette.warning.main,
  }

  function formatBackendMessage(message) {
    const regex = /^([\w.]+): Invalid type\. Expected: ([\w]+), given: ([\w]+)$/
    const match = message.match(regex)
    if (match) {
      const [_, field, expectedType, givenType] = match
      const fieldMapping = {
        'patient.dateOfBirth': 'Date of Birth',
      }
      const userFriendlyField = fieldMapping[field] || field
      return `${userFriendlyField} is invalid. Expected a ${expectedType}, but received ${givenType}.`
    }
    return 'An unexpected error occurred. Please try again.'
  }

  const redirectToPatient = () => {
    onProceed(false)
    history.push(`/v2/patient/${patient.id}/profile`)
  }

  useEffect(() => {
    if (eligibilityInfo?.response) {
      const {
        coInsurances,
        deductibles,
        limitationAndMaximum,
        activeCoverage,
        patient,
        subscriber,
      } = eligibilityInfo.response

      const healthObject = {
        insurance: eligibilityInfo?.insurance,
        plan: patient?.plan,
      }

      const [activeCoverageData] = activeCoverage || []

      const errors = eligibilityInfo?.response?.errors || []

      if (errors?.length) {
        onProceed(false)
      } else {
        onProceed(true)
      }

      setLimitations(limitationAndMaximum)
      setDeductibles(deductibles)
      setCoInsurances(coInsurances)
      setActiveCoverage(activeCoverageData)
      setPatientData(patient)
      setSubscriberData(subscriber)
      setHealthPlan(healthObject)
      setErrors(errors)
    }
  }, [eligibilityInfo])

  if (isLoading && !isError)
    return (
      <ContentWrapper>
        <Typography
          variant="h5"
          fontWeight="300"
          sx={{ textAlign: 'center', marginTop: '40px' }}
        >
          Loading eligibility...
        </Typography>
      </ContentWrapper>
    )

  if (isError)
    return (
      <ContentWrapper>
        <Typography
          variant="h5"
          fontWeight="300"
          sx={{ textAlign: 'center', marginTop: '40px' }}
        >
          It wasn't possible to load the eligibility.
        </Typography>
      </ContentWrapper>
    )

  if (!!errors?.length)
    return (
      <ContentWrapper>
        <Typography variant="h3" fontWeight="500">
          Errors
        </Typography>

        {errors.map((error, index) => (
          <Typography
            key={index}
            variant="h5"
            fontWeight="300"
            sx={{ marginTop: '10px' }}
            color="error"
          >
            {formatBackendMessage(error)}
          </Typography>
        ))}

        <Grid display="flex" width={'90%'} justifyContent={'flex-end'} gap={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => redirectToPatient()}
          >
            Fix Errors
          </Button>
        </Grid>
      </ContentWrapper>
    )

  const coInsurancesFields = {
    insuranceType: { label: 'Insurance Type' },
    serviceType: { label: 'Service Type' },
    network: { label: 'Network' },
    coverageLevel: { label: 'Coverage Level' },
    percent: { label: 'Percentage' },
  }

  const deductiblesFields = {
    insuranceType: { label: 'Insurance Type' },
    serviceType: { label: 'Service Type' },
    network: { label: 'Network' },
    amount: { label: 'Amount' },
    coverageLevel: { label: 'Coverage Level' },
  }

  const limitationsFields = {
    insuranceType: { label: 'Insurance Type' },
    serviceType: { label: 'Service Type' },
    network: { label: 'Network' },
    planPeriod: { label: 'Plan Period' },
    amount: { label: 'Amount' },
  }

  return (
    <ContentWrapper>
      <Grid xs={12} mb="24px">
        <Button
          key={name}
          variant="contained"
          disableElevation
          sx={{
            background: statusColors[eligibility?.status],
            color: theme.palette.background.default,
            width: 'auto',
            minWidth: '40px',
            height: '20px',
            mt: '15px',
            cursor: 'not-allowed',
            pointerEvents: 'none',
          }}
        >
          {eligibility?.status}
        </Button>
      </Grid>
      {patientData && (
        <AccordionStyled
          noPaddingTitle
          expanded={expandedPanel === 'patientData'}
          noBackground
          onChange={() =>
            setExpandedPanel(
              expandedPanel === 'patientData' ? null : 'patientData'
            )
          }
          title={<Typography>Patient</Typography>}
          content={
            <PatientData
              patient={patientData}
              existentPatientData={patient}
              insuranceId={eligibility?.insurance?.id}
              eligibilityId={eligibility?.id}
            />
          }
        />
      )}

      {subscriberData && (
        <AccordionStyled
          noPaddingTitle
          expanded={expandedPanel === 'subscriberData'}
          noBackground
          onChange={() =>
            setExpandedPanel(
              expandedPanel === 'subscriberData' ? null : 'subscriberData'
            )
          }
          title={<Typography>Subscriber</Typography>}
          content={<SubscriberData subscriber={subscriberData} />}
        />
      )}

      {healthPlan && (
        <AccordionStyled
          noPaddingTitle
          expanded={expandedPanel === 'healthplan'}
          noBackground
          onChange={() =>
            setExpandedPanel(
              expandedPanel === 'healthplan' ? null : 'healthplan'
            )
          }
          title={<Typography>Insurance & Plan</Typography>}
          content={<HealthPlan healthplan={healthPlan} />}
        />
      )}
      {activeCoverage && (
        <AccordionStyled
          noPaddingTitle
          expanded={expandedPanel === 'activeCoverage'}
          noBackground
          onChange={() =>
            setExpandedPanel(
              expandedPanel === 'activeCoverage' ? null : 'activeCoverage'
            )
          }
          title={<Typography>Active Coverage</Typography>}
          content={<ActiveCoverage activeCoverage={activeCoverage} />}
        />
      )}
      {activeCoverage && (
        <AccordionStyled
          noPaddingTitle
          expanded={expandedPanel === 'coInsurances'}
          onChange={() =>
            setExpandedPanel(
              expandedPanel === 'coInsurances' ? null : 'coInsurances'
            )
          }
          title={<Typography>Co-Insurance</Typography>}
          content={
            <EligibilityItem
              data={coInsurances}
              fields={coInsurancesFields}
              searchLabel="Search Co-Insurance"
            />
          }
        />
      )}
      {deductibles && (
        <AccordionStyled
          noPaddingTitle
          expanded={expandedPanel === 'deductibles'}
          onChange={() =>
            setExpandedPanel(
              expandedPanel === 'deductibles' ? null : 'deductibles'
            )
          }
          title={<Typography>Deductible</Typography>}
          content={
            <EligibilityItem
              data={deductibles}
              fields={deductiblesFields}
              searchLabel="Search Deductible"
            />
          }
        />
      )}
      {limitations && (
        <AccordionStyled
          noPaddingTitle
          expanded={expandedPanel === 'limitations'}
          onChange={() =>
            setExpandedPanel(
              expandedPanel === 'limitations' ? null : 'limitations'
            )
          }
          title={<Typography>Limitation and Maximum</Typography>}
          content={
            <EligibilityItem
              data={limitations}
              fields={limitationsFields}
              type="Search Limitation and Maximum"
            />
          }
        />
      )}
    </ContentWrapper>
  )
}

export default Show
