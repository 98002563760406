import React from 'react'
import { ContentWrapper, Value, Item } from '../styles'
import { format, parseISO } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import MoreIcon from '@mui/icons-material/MoreVert'
import Typography from '@/v2/components/common/Typography'
import IconMenu from '@/v2/components/common/IconMenu'
import Button from '@/v2/components/common/Button'
import useClaimOptions from './useClaimOptions'

const ClaimInfo = ({ claim, patient }) => {
  const theme = useTheme()
  const { createdAt, updatedAt, insurance, status, subscriber } = claim
  const { options, statusColors, outlinedStatus, getLabel } = useClaimOptions(
    status,
    claim.id,
    patient.id,
    insurance?.id,
    claim,
    patient
  )

  return (
    <Item theme={theme.palette}>
      <ContentWrapper>
        <Value>
          <Typography fontSize="14px" sx={{ mb: '2px' }}>
            Created
          </Typography>
          <Typography bold fontSize="14px" sx={{ mb: '2px' }}>
            {format(parseISO(createdAt), 'MMMM dd, yyyy HH:mm')}
          </Typography>
        </Value>
        <Value>
          <Typography fontSize="14px" sx={{ mb: '2px' }}>
            Subscriber
          </Typography>
          <Typography bold fontSize="14px" sx={{ mb: '2px' }}>
            {subscriber?.firstName || subscriber?.name || '-'}{' '}
            {subscriber?.lastName || ''}
          </Typography>
        </Value>
        <Value>
          <Typography fontSize="14px" sx={{ mb: '2px' }}>
            Insurance
          </Typography>
          <Typography bold fontSize="14px">
            {insurance?.payer?.name}
          </Typography>
        </Value>
        <Value>
          <Typography fontSize="14px" sx={{ mb: '2px' }}>
            Last Edit
          </Typography>
          <Typography bold fontSize="14px" sx={{ mb: '2px' }}>
            {format(parseISO(updatedAt), 'MMMM dd, yyyy HH:mm')}
          </Typography>
        </Value>
        <Value>
          <Button
            key={name}
            variant={outlinedStatus.includes(status) ? 'outlined' : 'contained'}
            sx={{
              background: outlinedStatus.includes(status)
                ? 'transparent'
                : statusColors[status],
              color: outlinedStatus.includes(status)
                ? statusColors[status]
                : theme.palette.background.default,
              borderColor: outlinedStatus.includes(status)
                ? statusColors[status]
                : 'transparent',
              width: 'auto',
              minWidth: '40px',
              height: '20px',
              mt: '15px',
              cursor: 'not-allowed',
              pointerEvents: 'none',
            }}
          >
            {getLabel(status)}
          </Button>
        </Value>
      </ContentWrapper>
      <IconMenu
        options={options}
        icon={<MoreIcon fontSize="small" />}
        aria-label="More"
        title="More"
      />
    </Item>
  )
}

export default ClaimInfo
