import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@/v2/components/common/Typography'
import Divider from '@/v2/components/common/Divider'
import { format } from 'date-fns'
import Accordion from '@/v2/components/common/Accordion'
import styled from 'styled-components'

const Wrapper = styled.div``

const Patient = ({ patient = {} }) => (
  <Accordion
    noPaddingTitle
    defaultExpanded={false}
    title={
      <Typography variant="h5" fontWeight={500}>
        Patient
      </Typography>
    }
    content={
      <Wrapper>
        {patient && (
          <>
            <Grid item xs={12} mb={1}>
              <Typography variant="h5" fontWeight={500}>
                Name:{' '}
                <Typography variant="span" fontWeight={200}>
                  {patient?.firstName} {patient?.lastName}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography variant="h5" fontWeight={500}>
                Gender:{' '}
                <Typography variant="span" fontWeight={200}>
                  {patient?.gender}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography variant="h5" fontWeight={500}>
                Date of Birth:{' '}
                <Typography variant="span" fontWeight={200}>
                  {format(new Date(patient?.dob), 'MM/dd/yyyy')}
                </Typography>
              </Typography>
            </Grid>
            <Divider margin="20px 0px" />
            <Grid item xs={12} mb={1}>
              <Typography variant="h5" fontWeight={500}>
                Patient Address Information:
              </Typography>
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography variant="h5" fontWeight={500}>
                City:{' '}
                <Typography variant="span" fontWeight={200}>
                  {patient?.city}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography variant="h5" fontWeight={500}>
                State:{' '}
                <Typography variant="span" fontWeight={200}>
                  {patient?.state}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography variant="h5" fontWeight={500}>
                Zipcode:{' '}
                <Typography variant="span" fontWeight={200}>
                  {patient?.zipcode}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography variant="h5" fontWeight={500}>
                Address:{' '}
                <Typography variant="span" fontWeight={200}>
                  {patient?.address1}
                </Typography>
              </Typography>
            </Grid>
          </>
        )}
      </Wrapper>
    }
  />
)

export default Patient
