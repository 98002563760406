import React, { useState } from 'react'
import Modal from '@/v2/components/common/Modal'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useAddCheckEligibility } from '@/v2/hooks/insurance/useEligibilities'
import Form from './eligibility-form/Form'
import Show from './eligibility-show/Show'
import { validateEligibility } from './eligibility-form/validator'
import { useProviders } from '@/v2/hooks/insurance/useEligibilities'
import { useInsurances } from '@/v2/hooks/insurance/useInsurances'
import useModals from '@/v2/hooks/useModals'

const EligibilityForm = ({
  patient,
  eligibility = null,
  modalType,
  isOpen,
  onClose,
}) => {
  const [values, setValues] = useState({
    insuranceId: '',
    providerNPI: '',
  })
  const [error, setError] = useState({})
  const [canProceed, setCanProceed] = useState(true)
  const { addCheckEligibility } = useAddCheckEligibility(patient.id)
  const { onOpenSnackbar } = useSnackbarContext()
  const { providers, isLoading: loadingProviders } = useProviders()
  const { insurances, isLoading: loadingInsurances } = useInsurances(patient.id)
  const { goTo } = useModals()

  const handleCheckEligibility = async () => {
    const selectedProvider = providers.find(
      provider => provider.npi === values.providerNPI
    )
    try {
      const res = await addCheckEligibility({
        insuranceId: values.insuranceId,
        eligibility: {
          providerAttributes: {
            firstName:
              selectedProvider?.firstName ||
              selectedProvider.name.split(' ')[0],
            lastName:
              selectedProvider?.lastName || selectedProvider.name.split(' ')[1],
            npi: selectedProvider?.npi,
            taxId: selectedProvider?.taxId,
          },
        },
      })
      if (!res.error) {
        onOpenSnackbar('Eligibility added', 'success')
        onClose()
        goTo(`/v2/patient/${patient.id}/eligibility/show`, {
          modalType: 'show',
          eligibility: {
            id: res.id,
            insurance: { id: values.insuranceId },
          },
          patient,
        })
      }
    } catch (error) {
      console.log('error', error)
      onOpenSnackbar(`${error}`, 'error')
    }
  }

  const handleSubmit = async () => {
    const validErrors = await validateEligibility(values)
    setError(validErrors || {})

    if (!validErrors) {
      handleCheckEligibility()
    }
  }

  const proceedWithClaim = () => {
    if (!canProceed) {
      onOpenSnackbar(
        'Please fix all the eligibility errors and check again before proceeding with claim.',
        'error'
      )
      return
    }
    onClose()
    goTo(`v2/patient/${patient.id}/claim/view`, {
      modalType: 'create',
      patientId: patient.id,
      insurance: eligibility?.insurance || null,
      patient,
    })
  }

  const handleProceed = value => {
    setCanProceed(value)
  }

  return (
    <Modal
      isOpen={isOpen}
      title={modalType === 'show' ? 'Eligibility' : 'Check Eligibility'}
      confirmLabel={modalType === 'show' ? 'Proceed with Claim' : 'Check'}
      cancelLabel={modalType === 'show' ? 'Close' : 'Cancel'}
      onConfirm={modalType === 'show' ? proceedWithClaim : handleSubmit}
      onClose={onClose}
      fixedHeight={modalType === 'show' ? '70vh' : '340px'}
      fixedWidth="36wh"
      showClose={modalType !== 'show'}
      showConfirm={canProceed}
    >
      {modalType === 'show' ? (
        <Show
          eligibility={eligibility}
          patient={patient}
          onProceed={handleProceed}
        />
      ) : (
        !loadingProviders &&
        !loadingInsurances && (
          <Form
            values={values}
            error={error}
            providers={providers}
            insurances={insurances}
            onChange={value => setValues({ ...values, ...value })}
          />
        )
      )}
    </Modal>
  )
}

export default EligibilityForm
