import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@/v2/components/common/Divider'
import Button from '@/v2/components/common/Button'
import { Wrapper } from './styles'
import { useSyncPatientData } from '@/v2/hooks/insurance/useEligibilities'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { format } from 'date-fns'

const PatientData = ({
  patient,
  existentPatientData,
  insuranceId,
  eligibilityId,
}) => {
  const { onOpenSnackbar } = useSnackbarContext()
  const [differences, setDifferences] = useState(null)
  const [showSync, setShowSync] = useState(false)
  const [showDifferences, setShowDifferences] = useState(false)
  const { syncPatientData, isLoading } = useSyncPatientData(
    insuranceId,
    eligibilityId
  )

  const handleSync = async () => {
    const res = await syncPatientData()
    if (!res.error) {
      onOpenSnackbar('Patient data synced successfully', 'success')
      setShowDifferences(false)
      setShowSync(false)
    } else {
      onOpenSnackbar('Failed to sync patient data', 'error')
    }
  }

  if (!patient)
    return (
      <Typography variant="span" fontWeight={200} textAlign="center">
        No Patient data found
      </Typography>
    )

  const getDifferences = (patient, existentPatientData) => {
    const differences = {}

    // Exclude keys that shouldn't be compared
    const excludedKeys = ['plan', 'relationship', 'gender']

    // Helper to normalize date formats
    const normalizeDate = date => {
      if (!date) return null // Treat null or empty as null
      const [first, second, third] = date.includes('/')
        ? date.split('/')
        : date.split('-')
      if (date.includes('/')) {
        // Format MM/DD/YYYY to YYYY-MM-DD
        return `${third}-${first.padStart(2, '0')}-${second.padStart(2, '0')}`
      }
      if (date.includes('-')) {
        // Format YYYY-DD-MM to YYYY-MM-DD
        return `${first}-${third.padStart(2, '0')}-${second.padStart(2, '0')}`
      }
      return date // Return as is if format is unknown
    }

    const normalizeValue = (value, key = null) => {
      if (value === null || value === undefined || value === '') return null // Treat empty and null as equivalent
      if (key === 'dateOfBirth' || key === 'dob') return normalizeDate(value) // Normalize date fields
      if (typeof value === 'string') return value.trim() // Normalize strings
      return value // Return value as is for other types
    }

    const normalizeObject = obj => {
      const normalized = {}
      Object.keys(obj || {}).forEach(key => {
        normalized[key] = normalizeValue(obj[key], key)
      })
      return normalized
    }

    Object.keys(patient).forEach(key => {
      if (!excludedKeys.includes(key)) {
        if (key === 'address') {
          // Special handling for address fields
          const patientAddress = normalizeObject(patient[key])
          const existentAddress = {
            address1: existentPatientData.address1 || null,
            address2: existentPatientData.address2 || null,
            city: existentPatientData.city || null,
            state: existentPatientData.state || null,
            zipCode: existentPatientData.zipcode || null,
          }

          const nestedDifferences = {}
          Object.keys(patientAddress).forEach(nestedKey => {
            const currentValue = patientAddress[nestedKey]
            const existingValue = normalizeValue(existentAddress[nestedKey])

            if (currentValue !== existingValue) {
              nestedDifferences[nestedKey] = {
                current: currentValue,
                existing: existingValue,
              }
            }
          })

          if (Object.keys(nestedDifferences).length > 0) {
            differences[key] = nestedDifferences
          }
        } else if (typeof patient[key] === 'object' && patient[key] !== null) {
          // Handle other nested objects
          const nestedDifferences = {}
          const nestedPatientData = patient[key]
          const nestedExistentPatientData = existentPatientData[key] || {}

          Object.keys(nestedPatientData).forEach(nestedKey => {
            const currentValue = normalizeValue(
              nestedPatientData[nestedKey],
              nestedKey
            )
            const existingValue = normalizeValue(
              nestedExistentPatientData[nestedKey],
              nestedKey
            )

            if (currentValue !== existingValue) {
              nestedDifferences[nestedKey] = {
                current: currentValue,
                existing: existingValue,
              }
            }
          })

          if (Object.keys(nestedDifferences).length > 0) {
            differences[key] = nestedDifferences
          }
        } else {
          // Compare scalar values
          const mappedKey = key === 'dateOfBirth' ? 'dob' : key // Map keys where needed
          const currentValue = normalizeValue(patient[key], key)
          const existingValue = normalizeValue(
            existentPatientData[mappedKey],
            mappedKey
          )

          if (currentValue !== existingValue) {
            differences[key] = {
              current: currentValue,
              existing: existingValue,
            }
          }
        }
      }
    })

    return differences
  }

  useEffect(() => {
    const diff = getDifferences(patient, existentPatientData)
    if (Object.keys(diff).length > 0) {
      setDifferences(diff)
      setShowSync(true)
    } else {
      setShowSync(false)
    }
  }, [patient, existentPatientData])

  return (
    <Wrapper>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Name:{' '}
          <Typography variant="span" fontWeight={200}>
            {patient?.firstName} {patient?.lastName}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Gender:{' '}
          <Typography variant="span" fontWeight={200}>
            {patient?.gender}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Date of Birth:{' '}
          <Typography variant="span" fontWeight={200}>
            {patient?.dateOfBirth
              ? format(new Date(patient?.dateOfBirth), 'MM/dd/yyyy')
              : 'N/A'}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Relationship:{' '}
          <Typography variant="span" fontWeight={200}>
            {patient?.relationship}
          </Typography>
        </Typography>
      </Grid>
      <Divider margin="20px 0px" />
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Patient Address Information:
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          City:{' '}
          <Typography variant="span" fontWeight={200}>
            {patient?.address?.city}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          State:{' '}
          <Typography variant="span" fontWeight={200}>
            {patient?.address?.state}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Zipcode:{' '}
          <Typography variant="span" fontWeight={200}>
            {patient?.address?.zipcode}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Address:{' '}
          <Typography variant="span" fontWeight={200}>
            {patient?.address?.address1}
          </Typography>
        </Typography>
      </Grid>
      <Divider margin="20px 0px" />
      {showSync && !showDifferences && (
        <Grid display="flex" width={'100%'} justifyContent={'flex-end'} gap={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowDifferences(true)}
          >
            Sync Patient Data
          </Button>
        </Grid>
      )}
      {showDifferences && (
        <>
          <Grid item xs={12} mb={2}>
            <Typography variant="h4" fontWeight={500} mb="24px">
              The following data will be updated:
            </Typography>
            {Object.keys(differences).map((key, index) => (
              <Grid item xs={12} mb={2} key={index}>
                <Typography variant="h4" fontWeight={500}>
                  {key}:
                </Typography>
                {typeof differences[key].current !== 'undefined' ? (
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="span" fontWeight={200}>
                        New Data: {differences[key].current || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="span" fontWeight={200}>
                        Existing: {differences[key].existing || 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  Object.keys(differences[key]).map(
                    (nestedKey, nestedIndex) => (
                      <Grid item xs={12} mb={2} key={nestedIndex}>
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          fontSize="13px"
                        >
                          {nestedKey}:
                        </Typography>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="span" fontWeight={200}>
                              Current:{' '}
                              {differences[key][nestedKey].current || 'N/A'}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="span" fontWeight={200}>
                              Existing:{' '}
                              {differences[key][nestedKey].existing || 'N/A'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  )
                )}
              </Grid>
            ))}
          </Grid>

          <Grid
            display="flex"
            width={'100%'}
            justifyContent={'flex-end'}
            gap={1}
          >
            <Button
              variant="text"
              color="secondary"
              onClick={() => setShowDifferences(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleSync()}
              disabled={isLoading}
            >
              Confirm Patient Data Sync
            </Button>
          </Grid>
        </>
      )}
    </Wrapper>
  )
}

export default PatientData
