import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@/v2/components/common/Divider'
import { Wrapper } from './styles'

const SubscriberData = ({ subscriber }) => {
  if (!subscriber)
    return (
      <Typography variant="span" fontWeight={200} textAlign="center">
        No Subscriber data found
      </Typography>
    )

  return (
    <Wrapper>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Name:{' '}
          <Typography variant="span" fontWeight={200}>
            {subscriber?.firstName || subscriber?.name}{' '}
            {subscriber?.lastName || ''}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Gender:{' '}
          <Typography variant="span" fontWeight={200}>
            {subscriber?.gender}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Date of Birth:{' '}
          <Typography variant="span" fontWeight={200}>
            {subscriber?.dateOfBirth}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Relationship:{' '}
          <Typography variant="span" fontWeight={200}>
            {subscriber?.relationship}
          </Typography>
        </Typography>
      </Grid>
      <Divider margin="20px 0px" />
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Subscriber Address Information:
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          City:{' '}
          <Typography variant="span" fontWeight={200}>
            {subscriber?.address?.city}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          State:{' '}
          <Typography variant="span" fontWeight={200}>
            {subscriber?.address?.state}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Zipcode:{' '}
          <Typography variant="span" fontWeight={200}>
            {subscriber?.address?.zipcode}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Address:{' '}
          <Typography variant="span" fontWeight={200}>
            {subscriber?.address?.address1}
          </Typography>
        </Typography>
      </Grid>
      <Divider margin="20px 0px" />
    </Wrapper>
  )
}

export default SubscriberData
